import { useWeb3React } from '@web3-react/core'
import NextImage from 'shared/components/nextImage/NextImage'
import { useId, useRef, useState } from 'react'
import {
  fortmaticIcon,
  metamaskIcon,
  walletConnectIcon,
  walletLinkIcon,
  portisIcon,
  torusIcon,
  authereumIcon,
  magicIcon,
} from '../../shared/icons'
import {
  fortmatic,
  injected,
  walletConnect,
  walletLink,
  portis,
  torus,
} from '../../shared/wallet/connectors'
import { Container, SecondaryButton } from '../../styles/defaultStyles'
import {
  LoginContainer,
  LoginDescription,
  LoginLayout,
  LoginTitle,
  LoginWalletProvider,
  LoginWalletProviderDescription,
  LoginWalletProviderName,
  LoginWallets,
  LoginWalletsLayer,
  ShowMoreButton,
} from './LoginScreen.style'
import { useEffect } from 'react'
import {
  I_LoginOptions,
  useCheckIsUserBlocked,
  useLoginMutation,
  useUserLogoutQuery,
} from 'logic/reactQuery/userService'
import { useRouter } from 'next/router'
import toast from 'react-hot-toast'
import {
  setIsLoggedIn,
  setChainType,
  setUserFromLoginPage,
  setAccountLoading,
  setUserFromSidebar,
} from 'logic/redux/slices/userSlice'
import { useAppDispatch } from 'hooks/useAppDispatch'
import WalletToolTip from './WalletToolTip'
import dayjs from 'dayjs'
import { checkMetamask, toHex } from 'utilities/helpers'
import { useAppSelector } from 'hooks/useAppSelector'
import TippyToolTip from 'shared/components/tippyTooltip/TippyToolTip'
import { Spinner } from 'shared/components/spinner/Spinner'
import SuspendedModal from 'shared/components/suspendedModal/SuspendedModal'

const LoginScreen = () => {
  // Hooks -should be called at the top level of the component
  const [walletConnectLoading, setWalletConnectLoading] = useState<boolean>(false)
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false)
  const { activate, account, active, deactivate, error, chainId, library } = useWeb3React()
  const { mutateAsync, isLoading, data: loginInfo, error: loginError }: any = useLoginMutation()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector(state => state.theme)
  const { isLoggedIn, userFromLoginPage } = useAppSelector(state => state.user)
  // const [userFromLoginPage, setUserFromLoginPage] = useState<boolean>(false)
  const { mutateAsync: mutateLogoutUser } = useUserLogoutQuery()
  // const { data } = useCheckIsUserBlocked(account)
  const { data: isBlocked, refetch }: any = useCheckIsUserBlocked(account)
  const userSignatureAuthorization = async (userAddress: string) => {
    try {
      const signatureMessage =
        'action= signIn&' +
        'address' +
        userAddress +
        'signUp' +
        '&timestamp=' +
        new Date()?.toISOString()

      const signatureHash = await library.eth.personal.sign(signatureMessage, userAddress)
      const data: I_LoginOptions = {
        walletAddress: userAddress,
        signature: signatureHash,
        signature_message: signatureMessage,
      }

      if (!isBlocked) {
        const response = await mutateAsync(data)
        if (response?.message?.access_token) {
          dispatch(setIsLoggedIn(true))
          dispatch(setUserFromLoginPage(false))
          if (chainId) dispatch(setChainType(chainId))

          typeof window !== 'undefined' &&
            window.localStorage.setItem(
              'accessToken',
              JSON.stringify(response?.message?.access_token),
            )
          typeof window !== 'undefined' &&
            window.localStorage.setItem(
              'tokenExpiry',
              JSON.stringify(response?.message?.tokenExpirationDate),
            )

          const userInfo = [
            {
              walletAddress: userAddress,
              accessToken: response?.message?.access_token,
              tokenExpiry: response?.message?.tokenExpirationDate,
            },
          ]
          const isExistingUser =
            typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
          const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
          if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
            const userAuthInfoUpdated = userAuthInfo.filter(
              (user: any) => user.walletAddress !== userAddress,
            )
            if (userAuthInfoUpdated) {
              const newUserInfo = [...userAuthInfoUpdated, ...userInfo]
              typeof window !== 'undefined' &&
                window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
            }
          } else {
            typeof window !== 'undefined' &&
              window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
          }

          // @ts-ignore window.analytics undefined below
          window.analytics.track('User Authorized', {
            data,
          })
          // @ts-ignore
          toast.success('Wallet Connected')
          // @ts-ignore
          router.push(router.query.redirect || '/profile')
        } else {
          // @ts-ignore window.analytics undefined below
          window.analytics.track('User UnAuthorized', {
            data,
          })
        }
      }
    } catch (error: any) {
      setWalletConnectLoading(false)
      console.log(error?.message)
      toast(error?.message)
      // @ts-ignore window.analytics undefined below
      window.analytics.track('User UnAuthorized', {
        account,
      })
      disconnectWallet()
    }
  }

  const providers: any = {
    fortmatic,
    injected,
    walletConnect,
    walletLink,
    portis,
    torus,
    // authereum,
  }

  const setProvider = (connector: string) => {
    typeof window !== 'undefined' && window.localStorage.setItem('provider', connector)
  }

  const connectWallet: any = (connector: string) => {
    dispatch(setUserFromLoginPage(true))
    setWalletConnectLoading(true)
    activate(providers[connector], () => {
      typeof window !== 'undefined' && window.localStorage.removeItem('provider')
    })
    setProvider(connector)
  }

  const disconnectWallet: any = async () => {
    try {
      const response = await mutateLogoutUser()
      if (response?.success) {
        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
        const userAuthInfoUpdated = userAuthInfo.filter(
          (user: any) => user.walletAddress !== account,
        )
        if (userAuthInfoUpdated) {
          const newUserInfo = [...userAuthInfoUpdated]
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
        }
        if (typeof window !== 'undefined') {
          window.localStorage.removeItem('provider')
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('tokenExpiry')
        }
        deactivate()
        router.push('/login')
        dispatch(setIsLoggedIn(false))
        dispatch(setChainType(''))
        dispatch(setUserFromLoginPage(false))
        dispatch(setAccountLoading(false))
        dispatch(setUserFromSidebar(false))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const checkUserStatus = (address: string): boolean => {
    const isExistingUser = typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
    const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
    if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
      const userAuthInfoUpdated: any = userAuthInfo.find(
        (user: any) => user?.walletAddress?.toLowerCase() === address?.toLowerCase(),
      )
      if (userAuthInfoUpdated !== undefined && userAuthInfoUpdated !== null) {
        // const userToken: any = jwt_decode(userAuthInfoUpdated?.accessToken)
        // const isExpired = dayjs.unix(userToken?.exp).diff(dayjs()) < 1
        const expirationDate = userAuthInfoUpdated?.tokenExpiry
        // let isExpired = (new Date().getTime()) < expirationDate
        const isExpired = dayjs.unix(expirationDate).diff(dayjs()) < 1
        if (!isExpired) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (account && !isLoggedIn && userFromLoginPage) {
      const userHasValidToken = checkUserStatus(account)
      if (!userHasValidToken) {
        userSignatureAuthorization(account)
      } else {
        dispatch(setUserFromLoginPage(false))
        dispatch(setIsLoggedIn(true))
        if (chainId) dispatch(setChainType(chainId))
        toast.success('Wallet Connected')
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          account,
        })
        // @ts-ignore
        router.push(router.query.redirect || '/profile')
      }
    }
  }, [account, isLoggedIn, userFromLoginPage])

  useEffect(() => {
    if (error || loginError) {
      setWalletConnectLoading(false)
      toast.error(error?.message || loginError?.message)
    }
  }, [error, loginError])

  useEffect(() => {
    if (isLoading) {
      setWalletConnectLoading(true)
    }
  }, [isLoading])

  const walletsInfo = [
    {
      id: useId(),
      icon: fortmaticIcon,
      walletName: 'Email',
      connector: 'fortmatic',
    },
    {
      id: useId(),
      icon: metamaskIcon,
      walletName: 'Metamask',
      description: 'Popular',
      connector: 'injected',
    },
    {
      id: useId(),
      icon: walletConnectIcon,
      walletName: 'WalletConnect',
      connector: 'walletConnect',
    },
    {
      id: useId(),
      icon: walletLinkIcon,
      walletName: 'Coinbase',
      connector: 'walletLink',
    },
    {
      id: useId(),
      icon: torusIcon,
      walletName: 'Torus',
      connector: 'torus',
    },

    {
      id: useId(),
      icon: portisIcon,
      walletName: 'Portis',
      connector: 'portis',
    },
  ]
  const [walletIndex, setWalletIndex] = useState<any>(null)
  const handleWalletIndex = (i: number) => {
    setWalletIndex(i)
  }

  return (
    <>
      <LoginLayout>
        <Container>
          <LoginContainer>
            <LoginTitle themeMode={theme}>You need an Ethereum wallet to use Jungle.</LoginTitle>
            <LoginDescription themeMode={theme}>
              Connect with one of our available
              <TippyToolTip toolTipContent={<WalletToolTip />} placement="top">
                <span> wallet </span>
              </TippyToolTip>
              providers or create a new one.
            </LoginDescription>
            <LoginWallets showMoreOptions={showMoreOptions}>
              {walletsInfo.map((wallet: any, i: number) => (
                <LoginWalletProvider
                  themeMode={theme}
                  key={wallet.id}
                  onClick={
                    walletConnectLoading || isLoading
                      ? null
                      : () => {
                          if (wallet.walletName === 'Metamask') {
                            checkMetamask() ? connectWallet(wallet.connector) : null
                          } else {
                            connectWallet(wallet.connector)
                            handleWalletIndex(i)
                          }
                        }
                  }
                >
                  {(walletConnectLoading || isLoading) && <LoginWalletsLayer themeMode={theme} />}
                  <NextImage
                    width={33}
                    height={29}
                    src={wallet.icon}
                    alt={wallet.walletName}
                  ></NextImage>
                  <LoginWalletProviderName>
                    {wallet.walletName === 'Metamask' ? (
                      checkMetamask() ? (
                        wallet.walletName
                      ) : (
                        <p onClick={() => window.open('https://metamask.io/download/')}>
                          Install Metamask
                        </p>
                      )
                    ) : wallet.walletName === 'Coinbase' ? (
                      `${wallet.walletName} [Mobile]`
                    ) : (
                      wallet.walletName
                    )}
                  </LoginWalletProviderName>
                  {walletIndex === i &&
                  (wallet.description || walletConnectLoading || isLoading) ? (
                    <LoginWalletProviderDescription>
                      {walletConnectLoading || isLoading ? <Spinner /> : wallet.description}
                    </LoginWalletProviderDescription>
                  ) : null}
                </LoginWalletProvider>
              ))}
            </LoginWallets>
            <ShowMoreButton>
              <SecondaryButton onClick={() => setShowMoreOptions((prev: boolean) => !prev)}>
                {showMoreOptions ? 'Show Less Options' : 'Show More Options'}
              </SecondaryButton>
            </ShowMoreButton>
          </LoginContainer>
        </Container>
      </LoginLayout>

      {isBlocked && <SuspendedModal />}
    </>
  )
}

export default LoginScreen
